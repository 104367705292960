import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import { TitleSection } from "../components/TitleSection";
import { portfolioData } from "../types/portfolio-data";

export function Projects() {
  return (
    <Box
      sx={{
        pt: { xs: 2, sm: 0 },
        pb: 6,
      }}
    >
      <TitleSection
        title={portfolioData.projects_section.title}
        description={portfolioData.projects_section.description}
      />
      <Container maxWidth="md" sx={{ pt: 4 }}>
        <Grid container spacing={4}>
          {portfolioData.projects_section.projects?.map((project, index) => (
            <Grid item key={index} xs={12} sm={6} md={6}>
              <Card
                variant="outlined"
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardHeader
                  avatar={
                    project.icon_src && (
                      <Avatar
                        alt={project.name}
                        src={project.icon_src}
                        sx={{ bgcolor: "white" }}
                        aria-label={project.name}
                        style={{
                          border: "1px solid #dadada",
                          padding: project.padding ?? 5,
                          marginTop: -8,
                        }}
                      ></Avatar>
                    )
                  }
                  title={
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      sx={{ marginBottom: 0 }}
                    >
                      {project.link_project ? (
                        <Link
                          href={project.link_project}
                          underline="hover"
                          target={"_blank"}
                        >
                          {project.name}
                        </Link>
                      ) : (
                        project.name
                      )}
                    </Typography>
                  }
                  subheader={
                    <Typography
                      marginTop={-1}
                      variant="overline"
                      display="block"
                      gutterBottom
                      fontSize={12}
                    >
                      <Link
                        href={project.link_workspace}
                        underline="hover"
                        target={"_blank"}
                      >
                        {project.workspace}
                      </Link>
                    </Typography>
                  }
                />
                <CardContent sx={{ flexGrow: 1, paddingTop: 0 }}>
                  <Typography color={grey[600]}>
                    {project.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
