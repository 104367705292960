import DarkIcon from "@mui/icons-material/Brightness4";
import LightIcon from "@mui/icons-material/Brightness7";
import { Box, Button, useTheme } from "@mui/material";
import React from "react";
import { ColorContext } from "../context/Color";

export const ToggleThemeModeButton = () => {
  const theme = useTheme();
  const colorMode = React.useContext(ColorContext);

  return (
    <Box>
      <Button onClick={colorMode.toggleColorMode} color="inherit">
        {theme.palette.mode === "dark" ? <LightIcon /> : <DarkIcon />}
        <Box sx={{ ml: 1 }}>
          Modalità {theme.palette.mode === "dark" ? "Chiara" : "Scura"}
        </Box>
      </Button>
    </Box>
  );
};
