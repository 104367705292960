import {
  createTheme,
  CssBaseline,
  PaletteMode,
  ThemeProvider,
} from "@mui/material";
import React from "react";

import { ColorContext } from "./context/Color";
import { darkTheme } from "./theme/dark";
import { lightTheme } from "./theme/light";
import { NavBar } from "./theme/NavBar";
import { portfolioData } from "./types/portfolio-data";
import { AboutMe } from "./views/AboutMe";
import { Footer } from "./views/Footer";
import { Hero } from "./views/Hero";
import { Projects } from "./views/Projects";
import { Skills } from "./views/Skills";

function App() {
  const [mode, setMode] = React.useState<PaletteMode>("light");
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) =>
          prevMode === "light" ? "dark" : "light"
        );
      },
    }),
    []
  );

  const theme = React.useMemo(
    () => createTheme(mode === "light" ? lightTheme : darkTheme),
    [mode]
  );

  return (
    <ColorContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <NavBar />
        <main>
          <Hero />
          <AboutMe />
          <Skills />
          {portfolioData.projects_section.projects &&
            portfolioData.projects_section.show_section && <Projects />}
        </main>
        <Footer />
      </ThemeProvider>
    </ColorContext.Provider>
  );
}

export default App;
