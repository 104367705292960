import {
	Box,
	Container,
	Grid,
	LinearProgress,
	linearProgressClasses,
	styled,
	Typography,
} from "@mui/material";
import { TitleSection } from "../components/TitleSection";
import { portfolioData } from "../types/portfolio-data";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
	height: 8,
	borderRadius: 5,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor:
			theme.palette.grey[theme.palette.mode === "light" ? 300 : 800],
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
	},
}));

export function Skills() {
	return (
		<Box
			sx={{
				pt: { xs: 2, sm: 0 },
				pb: 6,
			}}
		>
			<TitleSection
				title={portfolioData.skill_section.title}
				description={portfolioData.skill_section.description}
			/>
			<Container maxWidth="md" sx={{ pt: 4 }}>
				<Grid container spacing={0} xs={12} sm={12} md={12}>
					<Grid item xs={6} sm={6} md={6} sx={{ pr: { md: 2, xs: 1 } }}>
						{portfolioData.skill_section.languages.map((item, index) => (
							<Box key={index}>
								<Typography
									marginTop={0}
									variant="overline"
									display="block"
									gutterBottom
									fontSize={11}
									sx={{
										marginBottom: 0,
										px: 0.3,
										textTransform: "uppercase",
										fontWeight: "900",
										color: "text.secondary",
									}}
								>
									{item.name}
								</Typography>
								<Box sx={{ width: "100%", marginBottom: 2 }}>
									<BorderLinearProgress
										variant="determinate"
										value={item.percentage}
										sx={{
											[`& .${linearProgressClasses.bar}`]: {
												backgroundColor: item.color,
											},
										}}
									/>
								</Box>
							</Box>
						))}
					</Grid>
					<Grid item xs={6} sm={6} md={6} sx={{ pl: { md: 2, xs: 1 } }}>
						{portfolioData.skill_section.frameworks.map((item, index) => (
							<Box key={index}>
								<Typography
									marginTop={0}
									variant="overline"
									display="block"
									gutterBottom
									fontSize={11}
									sx={{
										marginBottom: 0,
										px: 0.3,
										textTransform: "uppercase",
										fontWeight: "900",
										color: "text.secondary",
									}}
								>
									{item.name}
								</Typography>
								<Box sx={{ width: "100%", marginBottom: 2 }}>
									<BorderLinearProgress
										variant="determinate"
										value={item.percentage}
										sx={{
											[`& .${linearProgressClasses.bar}`]: {
												backgroundColor: item.color,
											},
										}}
									/>
								</Box>
							</Box>
						))}
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}
