import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Avatar, Box, useScrollTrigger } from "@mui/material";
import React, { useEffect, useState } from "react";

import { portfolioData } from "../types/portfolio-data";
import { SocialButton } from "../components/SocialButton";
import { ButtonDownloadCV } from "../components/ButtonDownloadCV";

export const NavBar = () => {
	const [isVisible, setIsVisible] = useState(false);

	const listenToScroll = () => {
		let heightToHideFrom = 0;
		const winScroll =
			document.body.scrollTop || document.documentElement.scrollTop;

		if (winScroll > heightToHideFrom) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", listenToScroll);
		return () => window.removeEventListener("scroll", listenToScroll);
	}, []);

	return (
		<>
			<ElevationScroll>
				<AppBar
					elevation={0}
					sx={{
						bgcolor: "background.paper",
						backdropFilter: "blur(20px)",
						border: "1px solid transparent",
					}}
					color="transparent"
				>
					<Toolbar>
						<Avatar alt={portfolioData.name} src={portfolioData.image_src} />
						<Box sx={{ flexGrow: 1 }}>
							<Typography
								marginLeft={2}
								marginBottom={-2}
								marginTop={1}
								variant="h6"
								noWrap
								component="div"
								sx={{ display: { xs: "block", sm: "block" } }}
							>
								{portfolioData.name}
							</Typography>
							<Typography
								marginLeft={2}
								marginTop={0}
								paddingTop={0.7}
								variant="overline"
								display="block"
								gutterBottom
								fontSize={10}
							>
								{portfolioData.work}
							</Typography>
						</Box>
						<Box sx={{ flexGrow: 1 }} />
						<SocialButton />
						{isVisible && (
							<Box sx={{ display: { xs: "none", sm: "block" } }}>
								<ButtonDownloadCV onNavBar={true} />
							</Box>
						)}
					</Toolbar>
				</AppBar>
			</ElevationScroll>
			<Toolbar />
		</>
	);
};

interface Props {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window?: () => Window;
	children: React.ReactElement;
}

function ElevationScroll(props: Props) {
	const { children, window } = props;
	// Note that you normally won't need to set the window ref as useScrollTrigger
	// will default to window.
	// This is only being set here because the demo is in an iframe.
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
		target: window ? window() : undefined,
	});

	return React.cloneElement(children, {
		sx: {
			bgcolor: trigger ? "background.paper" : "background.default",
			borderBottom: trigger
				? "1px solid rgba(0,0,0,0.09)"
				: "1px solid transparent",
			backdropFilter: "blur(20px)",
		},
	});
}
