import { PortfolioData } from "./portfolio.interface";

export const portfolioData: PortfolioData = {
  name: "Zoran Stojkov",
  title: "Full Stack Developer",
  work: "Full Stack Developer",
  image_src: "zoran_stojkov.webp",
  cv_src: "CV_STOJKOVZORAN.pdf",
  social: {
    linkedin: "https://www.linkedin.com/in/zoran-stojkov/",
    github: "https://github.com/stojkov-z",
    instagram: "https://www.instagram.com/stojkov_z/",
    twitter: "https://twitter.com/_stojkovzoran",
    email: "zoranstojkov99@gmail.com",
  },
  about: {
    title: "About Me",
    description:
      "Sono un ragazzo amante per la tecnologia e la programmazione. Progetto e sviluppo applicazioni per aziende e nel tempo libero mi piace sperimentare cose nuove.",
  },
  skill_section: {
    title: "Skills",
    description:
      "La curiosità e dedizione al mondo della programmazione mi hanno permesso di migliorare e di raffinare le mie competenze personali.",
    languages: [
      {
        color: "#818cf8",
        name: "php",
        percentage: 94
      },
      {
        color: "#fbbf24",
        name: "javascript",
        percentage: 92
      },
      {
        color: "#3078c6",
        name: "typescript",
        percentage: 89
      },
      {
        color: "#0bb7ed",
        name: "Docker",
        percentage: 75
      },
      {
        color: "#34b9ab",
        name: "python",
        percentage: 35
      },
    ],
    frameworks: [
      {
        color: "red",
        name: "Laravel",
        percentage: 93
      },
      {
        color: "primary",
        name: "React/React Native",
        percentage: 90
      },
      {
        color: "black",
        name: "Next.js",
        percentage: 86
      },
      {
        color: "#7ed06b",
        name: "Node.js",
        percentage: 65
      },
      {
        color: "#0b4b33",
        name: "Django",
        percentage: 45
      }
    ]
  },
  languages: [
    {
      name: "Typescript",
      icon_src: "icon-language/typescript.svg",
      icon_padding: 0
    },
    {
      name: "Javascript",
      icon_src: "icon-language/javascript.svg",
      icon_padding: 0
    },
    {
      name: "Php",
      icon_src: "icon-language/php.svg",
      icon_padding: 10
    },
    {
      name: "Python",
      icon_src: "icon-language/python.svg",
      icon_padding: 10
    },
  ],
  projects_section: {
    show_section: false,
    title: "Projects",
    description: "",
    projects: [
      {
        name: "Porfolio personale",
        description: "Web app che illustra il mio porfolio personale.",
        icon_src: "zoran_stojkov.png",
        link_project: "https://zoran.dev",
        link_workspace: "https://zoran.dev",
        workspace: "Zoran Stojkov",
        padding: 0
      },
      {
        name: "Edilizia Francesco Muto",
        description: "Sito vetrina per l'impresa Edile Francesco Muto.",
        icon_src: "icon-projects/logo_muto.svg",
        link_project: "https://ediliziafrancescomuto.it/",
        link_workspace: "https://zoran.dev",
        workspace: "Zoran Stojkov"
      },
      {
        name: "LCMS Despar Italia",
        description: "Learning content management system per l'erogazione dei corsi in modalità e-learning per Despar Italia. Il portale presidia la distribuzione dei corsi on-line, l'iscrizione degli studenti e il tracciamento delle attività on-line certificando la presenza.",
        icon_src: "icon-projects/despar.png",
        link_project: "",
        link_workspace: "https://www.dieffe.tech/",
        workspace: "Dieffetech",
      },
      {
        name: "LCMS Cnpadc",
        description: "Learning content management system per l'erogazione dei corsi in modalità e-learning per la Cassa Nazionale di Previdenza e Assistenza a favore dei Dottori Commercialisti. Il portale presidia la distribuzione dei corsi on-line, l'iscrizione degli studenti e il tracciamento delle attività on-line certificando la presenza.",
        icon_src: "icon-projects/cnpadc.ico",
        link_project: "",
        link_workspace: "https://www.dieffe.tech/",
        workspace: "Dieffetech",
      },
      {
        name: "ZhrExpert",
        description: "HR Expert è il motore di ricerca Zucchetti per informazioni, risposte su novità normative e adempimenti, ebook, approfondimenti sui temi di attualità HR, offerta formativa Zucchetti Academy e i corsi accreditati alla formazione continua di MG Consulting S.r.l",
        icon_src: "",
        link_project: "",
        link_workspace: "https://www.dieffe.tech/",
        workspace: "Dieffetech",
        padding: 0
      },
      {
        name: "Lavorofacile",
        description: "Piattaforma di informazione, e-commerce e formazione per consulenti del lavoro, commercialisti in materia di diritto del lavoro, previdenza e amministrazione del personale.",
        icon_src: "icon-projects/lavorofacile.jpeg",
        link_project: "https://www.lavorofacile.it/",
        link_workspace: "https://www.dieffe.tech/",
        workspace: "Dieffetech",
        padding: 0
      },
      {
        name: "GeniuSuite",
        description: "Piattaforma che agevola la gestione della Google Workspace for Education " +
          "nella scuola automatizzando tutte le procedure di avvio, gestione e manutenzione della struttra scolastica.",
        icon_src: "icon-projects/geniusuite.png",
        link_project: "https://geniusuite.com/",
        link_workspace: "https://it.linkedin.com/company/kedea-design",
        workspace: "Kedea",
      },
      {
        name: "Alfred",
        icon_src: "",
        description: "Piattaforma di comunicazione multimediale ideato per la diffusione in tempo reale i dati dalla Google Workspace for Education visualizzandoli a schermo in maniera semplice ed intuitiva con controllo da remoto.",
        link_project: "",
        link_workspace: "https://it.linkedin.com/company/kedea-design",
        workspace: "Kedea",
        padding: 0
      }, {
        name: "CampuStore for Didacta",
        description: "Progressive Web App di data entry per la Fiera Didacta utilizzata dai dipendenti di CampuStore e Kedea.",
        icon_src: "",
        link_project: "",
        link_workspace: "https://it.linkedin.com/company/kedea-design",
        workspace: "Kedea",
      },
      {
        name: "KeScuola",
        description: "Suite di piattaforme digitali per la scuola, registro elettronico, gestione alunni, servizi pubblica amministrazione, segreteria digitale, demateri, conservazione digitale a norma, classi virtuali e molti altri prodotti scolastici",
        icon_src: "",
        link_project: "",
        link_workspace: "https://it.linkedin.com/company/kedea-design",
        workspace: "Kedea",
      }
    ]
  }
};