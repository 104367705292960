import { Box } from "@mui/material";
import { CardHero } from "../components/CardHero";

export function Hero() {
	return (
		<Box
			sx={{
				pt: { xs: 6, sm: 3 },
				pb: 6,
			}}
		>
			<CardHero />
		</Box>
	);
}
