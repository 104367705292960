import { ThemeOptions } from "@mui/material";

export const darkTheme: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#40c4ff',
    },
    secondary: {
      main: '#ff3d00',
    },
    background: {
      default: 'rgba(16,24,39,1)',
      paper: 'rgba(27, 36, 51,0.7)',
    },
  },
  typography: {
    fontFamily: [
      "Open Sans"
    ].join(","),
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 16
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: 16,
          '&:hover': {
            boxShadow: "0px 0px 8px 3px rgb(1 116 188 / 70%)",
          },
        },
        contained: {
          boxShadow: "0px 0px 6px 3px rgb(1 116 188 / 54%)",
          borderRadius: 16,
          '&:hover': {
            boxShadow: "0px 0px 8px 3px rgb(1 116 188 / 70%)",
          },
        }
      }
    },
    // MuiAvatar: {
    //   styleOverrides: {
    //     root: {
    //       boxShadow: "0px 0px 14px 6px rgb(1 116 188 / 54%)",
    //     }
    //   }
    // }
  }
};