import { ThemeOptions } from "@mui/material";

export const lightTheme: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#0091ea',
      contrastText: 'rgba(243,244,246,1)',
    },
    secondary: {
      main: '#ff3d00',
    },
    background: {
      default: 'rgba(243,244,246,1)',
      paper: 'rgba(255,255,255,0.7)',
    },
  },
  typography: {
    fontFamily: [
      "Open Sans"
    ].join(",")
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 14
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          boxShadow: "none",
          borderRadius: 16,
          '&:hover': {
            boxShadow: "0px 0px 5px 1px rgb(1 116 188 / 60%)",
          },
        },
        root: {
          boxShadow: "none",
          borderRadius: 16,
        }
      }
    }
  }
};