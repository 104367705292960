import GitHubIcon from "@mui/icons-material/GitHub";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MailIcon from "@mui/icons-material/MailOutline";
import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { portfolioData } from "../types/portfolio-data";

export const SocialButton = () => {
  return (
    <Box sx={{ display: { xs: "flex", md: "flex" } }}>
      {portfolioData.social.github ? (
        <IconButton
          aria-label="GitHub"
          color="inherit"
          onClick={() =>
            window.open(
              portfolioData.social.github,
              "_blank",
              "noopener,noreferrer"
            )
          }
        >
          <GitHubIcon />
        </IconButton>
      ) : null}
      {portfolioData.social.linkedin ? (
        <IconButton
          aria-label="LinkedIn"
          color="inherit"
          onClick={() =>
            window.open(
              portfolioData.social.linkedin,
              "_blank",
              "noopener,noreferrer"
            )
          }
        >
          <LinkedInIcon />
        </IconButton>
      ) : null}
      {portfolioData.social.instagram ? (
        <IconButton
          aria-label="Instagram"
          color="inherit"
          onClick={() =>
            window.open(
              portfolioData.social.instagram,
              "_blank",
              "noopener,noreferrer"
            )
          }
        >
          <InstagramIcon />
        </IconButton>
      ) : null}
      {portfolioData.social.instagram ? (
        <IconButton
          aria-label="Mail"
          color="inherit"
          onClick={() =>
            window.open(
              "https://mail.google.com/mail/?view=cm&fs=1&to=" +
                portfolioData.social.email,
              "_blank",
              "noopener,noreferrer"
            )
          }
        >
          <MailIcon />
        </IconButton>
      ) : null}
    </Box>
  );
};
