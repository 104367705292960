import { Box, Card, Container, Grid } from "@mui/material";
import { TitleSection } from "../components/TitleSection";
import { portfolioData } from "../types/portfolio-data";

export function AboutMe() {
	return (
		<Box
			sx={{
				pt: { xs: 6, sm: 3 },
			}}
		>
			<TitleSection
				title={portfolioData.about.title}
				description={portfolioData.about.description}
			/>
			<Container sx={{ py: 8 }} maxWidth="sm">
				<Grid container spacing={4}>
					{portfolioData.languages.map((language, index) => (
						<Grid
							item
							key={index}
							xs={3}
							sm={3}
							md={3}
							container
							spacing={0}
							direction="column"
							alignItems="center"
							justifyContent="center"
						>
							<Card
								variant="outlined"
								sx={{
									width: 65,
									height: 65,
								}}
							>
								<img
									src={language.icon_src}
									alt={language.name}
									style={{
										padding: language.icon_padding,
										width: 65,
										height: 65,
									}}
								/>
							</Card>
						</Grid>
					))}
				</Grid>
			</Container>
		</Box>
	);
}
