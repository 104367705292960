import { Container, Typography } from "@mui/material";

interface Props {
  title: string;
  description: string;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
}

export const TitleSection = (props: Props) => {
  return (
    <Container maxWidth={props.maxWidth ?? "md"}>
      <Typography
        component="h1"
        variant="h2"
        align="center"
        color="text.primary"
        gutterBottom
      >
        {props.title}
      </Typography>
      <Typography variant="h6" align="center" color="text.secondary" paragraph>
        {props.description}
      </Typography>
      {/* <Stack sx={{ pt: 4 }} direction="row" spacing={2} justifyContent="center">
				<Button variant="contained">Main call to action</Button>
				<Button variant="outlined">Secondary action</Button>
			</Stack> */}
    </Container>
  );
};
