import { Box, Grid } from "@mui/material";
import { ToggleThemeModeButton } from "../components/ToggleThemeModeButton";
import { Copyright } from "./Copyright";

export function Footer() {
	return (
		<Box
			sx={{ bgcolor: "background.default", pt: 6, pb: 1 }}
			component="footer"
		>
			<Copyright />
			<Grid
				container
				spacing={0}
				direction="column"
				alignItems="center"
				justifyContent="center"
				sx={{ marginTop: 2 }}
			>
				<ToggleThemeModeButton />
			</Grid>
		</Box>
	);
}
