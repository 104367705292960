import { Link, Typography } from "@mui/material";
import { portfolioData } from "../types/portfolio-data";

export function Copyright() {
	return (
		<Typography variant="body2" color="text.secondary" align="center">
			{"© " + new Date().getFullYear() + " Made with ♥️ by "}
			<Link color="inherit" href={portfolioData.social.github}>
				{portfolioData.name}
			</Link>
			{/* <p>version {process.env.REACT_APP_VERSION}</p> */}
		</Typography>
	);
}
