import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { portfolioData } from "../types/portfolio-data";
import { BigAvatar } from "./BigAvatar";
import { ButtonDownloadCV } from "./ButtonDownloadCV";
import { SocialButton } from "./SocialButton";

export const CardHero = () => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Card variant="outlined" sx={{ px: 4, bgcolor: "backgroud.default" }}>
        <CardContent>
          <BigAvatar />
          <Typography variant="h4" component="div" align="center">
            {portfolioData.name}
          </Typography>
          <Typography color="text.secondary" align="center">
            {portfolioData.work}
          </Typography>
        </CardContent>
        <Divider sx={{}} />
        <CardActions sx={{ justifyContent: "center" }}>
          <SocialButton />
        </CardActions>
        <ButtonDownloadCV onNavBar={false} />
      </Card>
    </Grid>
  );
};
