import { Button, Grid } from "@mui/material";
import { portfolioData } from "../types/portfolio-data";

interface Props {
  onNavBar: boolean;
}

export const ButtonDownloadCV = (props: Props) => {
  const onClickHandler = () => {
    window.open(portfolioData.cv_src, "_blank", "noopener,noreferrer");
  };

  if (props.onNavBar) {
    return (
      <Button sx={{ ml: 2 }} variant="contained" onClick={onClickHandler}>
        Scarica Cv
      </Button>
    );
  } else {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ paddingBottom: 1 }}
      >
        <Button variant="contained" onClick={onClickHandler}>
          Scarica Cv
        </Button>
      </Grid>
    );
  }
};
