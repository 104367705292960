import { Avatar, Grid } from "@mui/material";
import { portfolioData } from "../types/portfolio-data";

export const BigAvatar = () => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ mb: 2 }}
    >
      <Avatar
        alt={portfolioData.name}
        src={portfolioData.image_src}
        sx={{
          width: 175,
          height: 175,
          borderColor: "background.paper",
          zIndex: 3,
        }}
      />
    </Grid>
  );
};
